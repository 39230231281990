import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const IntroAlt = ({
  children,
  showTitle,
  showSubTitle,
  showImgContent,
  bgImage,
  colType,
  title,
  subTitle,
  classNames,
  captive,
  showCegeInfo,
}) => {
  classNames = 'intro-section ' + classNames
  return (
    <div className={classNames}>
      <div className="two-col">
        <div
          className="left-col hidden-mobile"
          style={{ backgroundImage: `url('${bgImage}')` }}
        >
          {showImgContent && (
            <div className="img-content">
              <div className="img-title">
                <span className="icon-location"></span>
                Your area is{' '}
                <strong>{`${captive.userCity}, ${captive.userState}`}</strong>
              </div>
              <div className="img-list">
                <ul>
                  <li>
                    There are a total of <strong>33</strong> plans available in your area.
                  </li>
                  <li>
                    Finding a quote is completely <strong>FREE</strong>.
                  </li>
                  <li>
                    We’ve helped <strong>thousands</strong> find affordable insurance.
                  </li>
                  <li>
                    Getting a free quote takes less than <strong>5 minutes</strong>.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={colType}>
          {showCegeInfo && (
            <p className="cege-info pb3">
              A privately owned, non-government website powered by Cege Media.
            </p>
          )}
          {showTitle && (
            <div className="title-holder">
              <h1>{title}</h1>
              {showSubTitle && (
                <p className="sub-title" dangerouslySetInnerHTML={{ __html: subTitle }} />
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

IntroAlt.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  showImgContent: PropTypes.bool,
  showCegeInfo: PropTypes.bool,
  bgImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
}

IntroAlt.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  showImgContent: false,
  showCegeInfo: false,
  bgImage: '/images/bg-img-02.jpg',
  colType: 'right-col',
  title: 'Get an affordable Medicare Supplement Plan.',
  subTitle:
    'With Medicare Companion, expand your Medicare coverage. Plans offer additional coverage for prescription drugs, dental and vision.',
  classNames: '',
}

export default connect((state) => state, {})(IntroAlt)
