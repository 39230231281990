import React from 'react'

import surfer from '../../../images/surfer-bob.jpg'

const UseInsurance = () => {
  return (
    <div className="use-insurance bg-light">
      <div className="container flex">
        <div className="w-50 w-100-s content">
          <div className="w-90 w-100-s pt5 pt4-m pb4-l">
            <h2>Why should I use Medicare Companion?</h2>
            <p>
              Medicare parts A & B are filed with and regulated by your state’s Department
              of Insurance. Whether you buy from Medicare Companion, your local agent, or
              directly from the health insurance company, you’ll pay the same monthly
              premium for the same plan. This means that you can enjoy the advantages and
              convenience of shopping and purchasing your additional Medicare coverage
              through Medicare Companion and rest assured that you’re getting the best
              available price.
            </p>
          </div>
        </div>
        <div
          className="w-50 w-100-s bg-img"
          style={{ backgroundImage: `url('${surfer}')` }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  )
}

export default UseInsurance
