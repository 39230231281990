import React from 'react'

import family from '../../../icons/papers.svg'
import checklist from '../../../icons/paper-clock.svg'
import scales from '../../../icons/scales.svg'

const Benefits = ({ hideCta = false }) => {
  return (
    <div className="benefits-alt-section alt bg-light">
      <div className="container">
        <h2 className="h2">
          We’ve helped thousands find <br />
          affordable medicare insurance.
        </h2>
        <p>
          With Medicare Companion, expand your Medicare coverage. Plans offer additional
          coverage for prescription drugs, dental and vision.
        </p>
        <div className="boxes">
          <div className="box">
            <div className="content">
              <div className="icon">
                <img src={family} alt="Get Started" width="86" height="90" />
              </div>
              <h3>1. Get Started</h3>
              <p>
                Determine what’s most important to you in a medicare plan. Try comparing
                different plan types.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="content">
              <div className="icon">
                <img src={checklist} alt="Apply in 5 minutes" width="86" height="86" />
              </div>
              <h3>2. Apply in 5 minutes</h3>
              <p>
                Answer a few more questions to complete your application, and get the best
                quotes available.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="content">
              <div className="icon">
                <img src={scales} alt="Compare Quotes" width="94" height="77" />
              </div>
              <h3>3. Compare Quotes</h3>
              <p>
                We value your time. Compare quotes and choose your fav, then check it off
                your list.
              </p>
            </div>
          </div>
        </div>
        {!hideCta && (
          <a href="#form-box" className="btn">
            Start my quote
          </a>
        )}
      </div>
    </div>
  )
}

export default Benefits
