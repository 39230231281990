import React from 'react'
import clsx from 'clsx'
import { bool } from 'prop-types'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// import 'swiper/swiper-bundle.css'

import stars from './stars.svg'
import style from './styles.module.scss'

SwiperCore.use([Pagination])

const testimonialList = [
  {
    img: '/images/img-01.jpg',
    quote:
      'Trying to understand the in’s and out’s of Medicare was a bit overwhelming for me. Being able to talk to the experts at <strong>Medicare Companion made the process a lot easier.</strong>',
    cite: 'Elenore J.',
  },
  {
    img: '/images/img-02.jpg',
    quote:
      'I was looking for a better Medicare coverage but wanted to see the best rate I could get. <strong>I was able to get quotes within minutes</strong> and pick the plan I wanted.',
    cite: 'Thomas C.',
  },
  {
    img: '/images/img-03.jpg',
    quote:
      'I like how Medicare Companion was free to use and <strong>I could actually talk to a real person</strong> about how to get quotes from all the providers.',
    cite: 'Pam S.',
  },
]

const Testimonials = ({ bgNavy, hideHeader }) => {
  const params = {
    spaceBetween: 50,
    slidesPerView: 3,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  }

  // const getNumber = () => {
  //   const hours = new Date().getHours()
  //   const startNumber = 100
  //   // const endNumber = 350

  //   const calcNumber = Math.round((125 / 12) * hours + startNumber)

  //   return calcNumber.toLocaleString()
  // }

  return (
    <div className={clsx(style.testimonialsSection, bgNavy && style.bgNavy)}>
      <div className="container">
        {/* {!hideHeader && (
          <h2 className={style.header}>
            Today, we’ve helped <span>{getNumber()} people</span> find affordable Medicare
            insurance.
          </h2>
        )} */}
        <div className={style.testimonials}>
          <Swiper {...params} pagination={{ clickable: true }}>
            {testimonialList.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className={style.testimonial}>
                    <img
                      src={item.img}
                      alt={item.cite}
                      width="120"
                      height="120"
                      className={style.image}
                    />
                    <blockquote>
                      <q dangerouslySetInnerHTML={{ __html: item.quote }} />
                      <cite>{item.cite}</cite>
                      <img src={stars} alt="5 Star Rating" />
                    </blockquote>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

Testimonials.propTypes = {
  bgNavy: bool,
  hideHeader: bool,
}

Testimonials.defaultProps = {
  bgNavy: false,
  hideHeader: false,
}

export default Testimonials
